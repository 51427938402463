<template>
  <div class="management_box" v-bind:style="{ minHeight: Height + 'px' }">
    <div class="top_css">
      <div class="header">
        <!-- <h2>资源管理：</h2> -->
        <div class="input">
          <el-input
            prefix-icon="el-icon-search"
            v-model="searchInput"
            placeholder="请输入检索字段"
            clearable
          ></el-input>
          <el-button type="primary" round @click="onSubmit">搜索</el-button>
        </div>
      </div>
      <div class="search_field">
        <div class="sifts">
          <div class="sifts_tit">文献类型</div>
          <div class="choose">
            <!-- <el-button
              :class="item.case == 1 ? 'chosen' : 'bgcolor'"
              round
              size="mini"
              v-for="(item, index) in type"
              :key="index"
              @click="search_type(item, index)"
              >{{ item.book_type }}</el-button
            > -->
            <el-checkbox-group
              v-model="Types"
              @change="search_type_"
              class="checkbox_group_css"
            >
              <el-checkbox
                v-for="(item, index) in type"
                :key="index"
                :label="item.type"
                >{{ item.book_type }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="sifts">
          <div class="sifts_tit">全部状态</div>
          <div class="choose">
            <!-- <el-button
              :class="item.state == 1 ? 'chosen' : 'bgcolor'"
              round
              size="mini"
              v-for="(item, index) in states"
              :key="index"
              @click="search_states(item, index)"
              >{{ item.task }}</el-button
            > -->
            <el-checkbox-group
              v-model="condition"
              @change="search_states_"
              class="checkbox_group_css"
            >
              <el-checkbox
                v-for="(item, index) in states"
                :key="index"
                :label="item.status"
                >{{ item.task }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>

        <div class="sifts" style="align-items: center">
          <div class="sifts_tit">检索时间</div>
          <div class="choose" style="display: flex">
            <el-date-picker
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
            >
            </el-date-picker>
            <!-- <div>
              <span>开始时间:</span
              ><el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                size="mini"
                :picker-options="pickerOptionsStart"
                @change="change('start')"
              >
              </el-date-picker>
              <span>结束时间:</span
              ><el-date-picker
                v-model="value2"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                size="mini"
                :picker-options="pickerOptionsEnd"
                @change="change('end')"
              >
              </el-date-picker>
            </div> -->
          </div>
        </div>
        <div class="sifts">
          <div class="sifts_tit">是否已读</div>
          <div class="choose" style="display: flex">
            <el-radio-group v-model="radio" @change="radios(radio)">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="sifts">
          <div class="sifts_tit">需求来源</div>
          <div class="choose" style="display: flex">
            <el-radio-group v-model="origin" @change="origins(origin)">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">青梨</el-radio>
              <el-radio :label="2">微信群</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="sifts">
          <!-- <div class="sifts_tit">重新筛选：</div> -->
          <div class="choose" style="display: flex">
            <el-button size="mini" round @click="reset" class="reset_css"
              >重置</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="tables">
      <!-- <div class="ceList">
        <div class="tit">
          <span>需求内容：9787305238505</span>
          <span>需求标题：9787305238505</span>
        </div>
        <div class="type">
          <span>文献类型：中文书</span>
          <span>创建时间：2022-01-17 </span>
          <span>下载链接</span>
          <span>需求状态</span>
        </div>
        <div class="operate">

        </div>
      </div> -->
      <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ fontSize: '14px', color: '#606266' }"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :cell-style="getCellStyle"
        :cell-class-name="getCellClassName"
      >
        <el-table-column type="selection" width="35" align="center">
          <!-- <div class="read_css"></div> -->
        </el-table-column>

        <el-table-column label="时间" align="center" width="170">
          <template slot="header">
            <el-dropdown @command="handleCommand">
              <span
                class="el-dropdown-link"
                style="
                  color: var(--Gray-Gray-500, #a1a5b7);
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
              >
                时间排序
                <img
                  src="../../../assets/imgs/2.2/descending.png"
                  style="margin: 0 0 0 8px"
                  alt=""
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('time_desc')"
                  >时间降序</el-dropdown-item
                >
                <el-dropdown-item :command="beforeHandleCommand('time_asc')"
                  >时间升序</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            {{ scope.row.timestamp | parseTime("{y}-{m}-{d} {h}:{i}:{s}") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="text"
          label="需求内容"
          align="left"
          min-width="400px"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <el-popover
                placement="bottom-start"
                @show="contentDetail(scope.row)"
                width="300"
                trigger="hover"
                class="popovers"
              >
                <p style="margin: 0 0 5px 0">
                  <span>需求类型：</span
                  ><span
                    v-if="scope.row.text_name == '中文书'"
                    style="color: #d9a673"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '中文文献'"
                    style="color: #73a6d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '英文文献'"
                    style="color: #7373d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == 'Doi'"
                    style="color: #8676bd"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '链接地址'"
                    style="color: #73d9d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == 'PMID'"
                    style="color: #73d973"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == 'PMCID'"
                    style="color: #73d9a6"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '英文书'"
                    style="color: #d9d973"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '专利'"
                    style="color: #a673d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '标准'"
                    style="color: #d973d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '其他'"
                    style="color: #d973a6"
                    >{{ scope.row.text_name }}</span
                  >
                </p>
                <p v-if="scope.row.title" style="margin: 0 0 5px 0">
                  <span>需求标题：</span><span>{{ scope.row.title }}</span>
                </p>
                <p style="margin: 0 0 5px 0">
                  <span>需求内容：</span><span>{{ scope.row.text }}</span>
                </p>
                <p style="margin: 0 0 5px 0">
                  <span>识别内容：</span
                  ><span v-html="scope.row.original_text"></span>
                </p>

                <p style="margin: 0 0 5px 0">
                  <span>备&emsp;&emsp;注：</span
                  ><span>{{ scope.row.error_msg }}</span>
                </p>

                <span slot="reference" class="oneRow" style="cursor: pointer">
                  <el-tag
                    size="mini"
                    v-if="scope.row.text_name == '中文书'"
                    style="background: #d9a673; color: white"
                    >中文书</el-tag
                  >

                  <el-tag
                    v-if="scope.row.text_name == '中文文献'"
                    size="mini"
                    round
                    style="background: #73a6d9; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == '英文文献'"
                    size="mini"
                    round
                    style="background: #7373d9; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == 'Doi'"
                    size="mini"
                    round
                    style="background: #8676bd; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == '链接地址'"
                    size="mini"
                    round
                    style="background: #73d9d9; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == 'PMID'"
                    size="mini"
                    round
                    style="background: #73d973; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == 'PMCID'"
                    size="mini"
                    round
                    style="background: #73d9a6; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == '英文书'"
                    size="mini"
                    round
                    style="background: #d9d973; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == '专利'"
                    size="mini"
                    round
                    style="background: #a673d9; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == '标准'"
                    size="mini"
                    round
                    style="background: #d973d9; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.text_name == '其他'"
                    size="mini"
                    round
                    style="background: #d973a6; color: white"
                    >{{ scope.row.text_name }}</el-tag
                  >
                  <el-tag
                    v-if="scope.row.data_from == 'ql_table'"
                    size="mini"
                    round
                    style="
                      background: #ffe9ef !important;
                      color: #e2426b !important;
                      border-color: #ffe9ef !important;
                    "
                    >青梨</el-tag
                  >
                  <!--  style="background: #62b1f1; color: white" -->
                  <el-tag
                    v-if="scope.row.data_from !== 'ql_table'"
                    size="mini"
                    round
                    style="
                      background: #ffe9ef !important;
                      color: #e2426b !important;
                      border-color: #ffe9ef !important;
                    "
                    >微信</el-tag
                  >
                  <!-- style="background: #62b1f1; color: white" -->
                  <el-tag
                    v-if="scope.row.file_extension"
                    size="mini"
                    round
                    style="
                      background: var(--Gray-100, #f5f5f4) !important;
                      color: var(--Gray-500, #79716b) !important;
                      border-color: var(--Gray-100, #f5f5f4) !important;
                    "
                    >{{ scope.row.file_extension }}</el-tag
                  >
                  <span class="row_text_css">
                    {{ scope.row.text }}
                  </span>

                  <i
                    class="el-icon-warning-outline"
                    style="
                      -webkit-transform: rotate(180deg);
                      -moz-transform: rotate(180deg);
                      -o-transform: rotate(180deg);
                      transform: rotate(180deg);
                    "
                  ></i>
                </span>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="original_text"
          label="文献标题"
          align="left"
          min-width="300"
        >
          <template slot-scope="scope">
            <el-popover
              placement="bottom-start"
              @show="titleDetail(scope.row)"
              trigger="hover"
              class="popovers"
            >
              <p style="width: 200px">{{ scope.row.title }}</p>
              <p slot="reference" class="oneRow" style="cursor: pointer">
                {{ scope.row.title }}
              </p>
            </el-popover>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="文献类型" align="center" width="100">
          <template slot-scope="scope">
            
            <el-button
              v-if="scope.row.text_name == '中文书'"
              size="mini"
              round
              style="background: #d9a673; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '中文文献'"
              size="mini"
              round
              style="background: #73a6d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '英文文献'"
              size="mini"
              round
              style="background: #7373d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == 'Doi'"
              size="mini"
              round
              style="background: #8676bd; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '链接地址'"
              size="mini"
              round
              style="background: #73d9d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == 'PMID'"
              size="mini"
              round
              style="background: #73d973; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == 'PMCID'"
              size="mini"
              round
              style="background: #73d9a6; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '英文书'"
              size="mini"
              round
              style="background: #d9d973; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '专利'"
              size="mini"
              round
              style="background: #a673d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '标准'"
              size="mini"
              round
              style="background: #d973d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '其他'"
              size="mini"
              round
              style="background: #d973a6; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="全部状态" align="center" width="100">
          <template slot-scope="scope" class="stats">
            <span v-if="scope.row.task_status == 2" style="color: #73b2d9"
              >处理成功</span
            >
            <span v-if="scope.row.task_status == -2" style="color: #d973a6"
              >处理失败</span
            >
            <span v-if="scope.row.task_status == -3" style="color: #d973a6"
              >已失效</span
            >
            <span v-if="scope.row.task_status == -1" style="color: #f56c6c"
              >已删除</span
            >
            <span v-if="scope.row.task_status == 0" style="color: #73d9cb"
              >待处理</span
            >
            <span v-if="scope.row.task_status == 1" style="color: #7373d9"
              >处理中</span
            >
            <span v-if="scope.row.task_status == 3" style="color: #d97373"
              >人工处理</span
            >
            <el-button
              size="mini"
              round
              style="background: #73b2d9; color: white; border: none"
              v-if="scope.row.task_status == 2"
              >处理成功</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -2"
              >处理失败</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -3"
              >已失效</el-button
            >
            <el-button
              size="mini"
              round
              type="danger"
              v-if="scope.row.task_status == -1"
              >已删除</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #73d9cb; color: white; border: none"
              v-if="scope.row.task_status == 0"
              >待处理</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #7373d9; color: white; border: none"
              v-if="scope.row.task_status == 1"
              >处理中</el-button
            >
            <el-button
              size="mini"
              round
              type="primary"
              v-if="scope.row.task_status == 3"
              style="background: #d97373; color: white; border: none"
              >人工处理</el-button
            >
          </template>
        </el-table-column> -->

        <el-table-column label="处理状态" align="center" width="180">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              round
              style="background: #73b2d9; color: white; border: none"
              v-if="scope.row.task_status == 2"
              >处理成功</el-button
            > -->
            <div
              v-if="time_or >= scope.row.timestamp"
              style="position: relative"
            >
              <el-button
                size="mini"
                round
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
                >已过期</el-button
              >
            </div>
            <div v-else style="display: flex; justify-content: center">
              <div
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
                v-if="scope.row.task_status == -2"
                @click="links(scope.row)"
              >
                处理失败
              </div>
              <!-- @click="links(scope.row)" -->
              <div
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
                v-if="scope.row.task_status == -3"
              >
                已失效
              </div>
              <div
                type="danger"
                v-if="scope.row.task_status == -1"
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
              >
                已删除
              </div>
              <div
                style="
                  color: var(--Yellow-500, #f79009);
                  background: var(--Yellow-50, #fffaeb);
                "
                class="status_btn_css"
                v-if="scope.row.task_status == 0"
              >
                待处理
              </div>
              <div
                style="
                  color: var(--Blue-500, #2e90fa);
                  background: var(--Blue-50, #eff8ff);
                "
                class="status_btn_css"
                v-if="scope.row.task_status == 1 || scope.row.task_status == 6"
              >
                处理中
              </div>
              <div
                v-if="scope.row.task_status == 3"
                style="
                  color: var(--Blue-500, #2e90fa);
                  background: var(--Blue-50, #eff8ff);
                "
                class="status_btn_css"
              >
                二次处理中
              </div>
              <div
                v-if="scope.row.task_status == 2 || scope.row.task_status == 5"
                style="
                  color: var(---600, #00b049);
                  background: var(--Green-50, #ecfdf3);
                "
                class="status_btn_css"
              >
                处理完成
              </div>
              <!-- <div class="pdfdown">
                <el-button
                  size="mini"
                  round
                  class="down_links"
                  style=""
                  v-if="
                    scope.row.task_status == 2 && scope.row.down_url !== '0'
                  "
                  @click="links(scope.row)"
                  >资源详情</el-button
                >
                <el-button
                  size="mini"
                  round
                  class="down_links"
                  style="background: #a2c667"
                  v-if="
                    scope.row.task_status == 5 && scope.row.down_url !== '0'
                  "
                  @click="links(scope.row)"
                  >相似推荐</el-button
                >
                <el-button
                  size="mini"
                  round
                  class="down_links"
                  style=""
                  v-if="scope.row.read_url"
                  @click="preview(scope.row)"
                  >在线阅读</el-button
                >
              </div> -->
            </div>

            <!-- <el-button
              size="mini"
              round
              v-if="scope.row.task_status !== 2 && scope.row.task_status !== -2"
              disabled
              >链接地址</el-button
            > -->

            <!-- <el-popover
              placement="bottom"
              width="240"
              trigger="click"
              class="popovers"
            > -->
            <!-- <el-button
              slot="reference"
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -2"
              @click="links(scope.row)"
              >失败原因</el-button
            > -->
            <!-- </el-popover> -->

            <!-- <img
              class="readable"
              v-if="scope.row.read == 0 && scope.row.task_status == 2"
              src="../../../assets/imgs/redSpot.png"
              alt=""
            />
            <img
              class="readable"
              v-if="scope.row.read == 1 && scope.row.task_status == 2"
              src="../../../assets/imgs/对勾.png"
              alt=""
            /> -->
          </template>
        </el-table-column>
        <el-table-column label="文件下载" align="center" width="180">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              round
              style="background: #73b2d9; color: white; border: none"
              v-if="scope.row.task_status == 2"
              >处理成功</el-button
            > -->
            <div
              class="pdfdown_css"
              v-if="scope.row.down_file && scope.row.down_file.length > 0"
            >
              <div class="file_s">
                <span
                  v-for="(item, index) in scope.row.down_file"
                  :key="index"
                  :style="
                    index < 3 && scope.row.task_status !== 5
                      ? ''
                      : 'display: none;'
                  "
                >
                  <span
                    v-if="index < 3 && scope.row.task_status !== 5"
                    class="down_file_css"
                    @click="downFileOpen(item, scope.row, index)"
                    style=""
                  >
                    <span v-if="index < 2">
                      链接<span v-if="index == 0">一</span
                      ><span v-else>二</span>
                    </span>
                    <span v-else> 更多 </span>
                  </span>
                  <span
                    v-if="scope.row.task_status == 5"
                    class="down_file_css"
                    @click="downFileOpen(item, scope.row, index)"
                  >
                    <span v-if="index < 2"> 相似资源 </span>
                  </span>
                </span>
              </div>
            </div>
            <span v-else style="color: #8d8f94">-</span>
            <!-- <div class="pdfdown">
              <el-button
                size="mini"
                round
                class="down_links"
                style=""
                v-if="scope.row.task_status == 2 && scope.row.down_url !== '0'"
                @click="links(scope.row)"
                >资源详情</el-button
              >
              <el-button
                size="mini"
                round
                class="down_links"
                style="background: #a2c667"
                v-if="scope.row.task_status == 5 && scope.row.down_url !== '0'"
                @click="links(scope.row)"
                >相似推荐</el-button
              >
              <el-button
                size="mini"
                round
                class="down_links"
                style=""
                v-if="scope.row.read_url"
                @click="preview(scope.row)"
                >在线阅读</el-button
              >
            </div> -->

            <!-- <el-button
              size="mini"
              round
              v-if="scope.row.task_status !== 2 && scope.row.task_status !== -2"
              disabled
              >链接地址</el-button
            > -->

            <!-- <el-popover
              placement="bottom"
              width="240"
              trigger="click"
              class="popovers"
            > -->
            <!-- <el-button
              slot="reference"
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -2"
              @click="links(scope.row)"
              >失败原因</el-button
            > -->
            <!-- </el-popover> -->

            <!-- <img
              class="readable"
              v-if="scope.row.read == 0 && scope.row.task_status == 2"
              src="../../../assets/imgs/redSpot.png"
              alt=""
            />
            <img
              class="readable"
              v-if="scope.row.read == 1 && scope.row.task_status == 2"
              src="../../../assets/imgs/对勾.png"
              alt=""
            /> -->
          </template>
        </el-table-column>
        <el-table-column label="双语翻译状态" align="center" width="130">
          <template slot="header">
            <span style="margin: 0 5px 0 0">双语翻译状态</span>
            <el-popover
              placement="bottom"
              width="280"
              trigger="hover"
              content="仅支持PDF格式的英文文献翻译成中文"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.yicai_task_id">
              <div
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
                v-if="
                  scope.row.yicai_status == 2 || scope.row.yicai_status == 3
                "
                @click="links(scope.row)"
              >
                翻译失败
              </div>
              <div
                v-else-if="scope.row.yicai_status == 0"
                style="
                  color: var(--Blue-500, #2e90fa);
                  background: var(--Blue-50, #eff8ff);
                "
                class="status_btn_css"
              >
                翻译中
              </div>
              <div
                v-else-if="scope.row.yicai_status == 1"
                style="
                  color: var(---600, #00b049);
                  background: var(--Green-50, #ecfdf3);
                "
                class="status_btn_css"
              >
                翻译完成
              </div>
              <span v-else style="color: #8d8f94">-</span>
            </div>
            <span v-else style="color: #8d8f94">-</span>
          </template>
        </el-table-column>
        <!--  align="center" width="120" -->
        <el-table-column
          label="操作"
          align="left"
          fixed="right"
          width="180"
          header-align="center"
        >
          <template slot-scope="scope">
            <div class="btns">
              <!-- <el-button
              size="mini"butto提取码
              round
              v-if="
                scope.row.text_code !== 'zhBook' || scope.row.chinese_url == ''
              "
              disabled
              >更多结果</el-button
            > -->

              <!-- <el-button
                size="mini"
                round
                disabled
                  v-if="scope.row.task_status == -1"
              
                >删除</el-button
              > -->
              <!-- <el-popconfirm
              title="确定删除吗？"
              v-if="scope.row.task_status !== -1"
              @confirm="remove(scope.row)"
            >
              <el-button
                type="text"
                slot="reference"
                v-if="scope.row.task_status !== -1"
                style="color: #006eff !important; font-weight: 500 !important"
                >删除</el-button
              >
            </el-popconfirm> -->
              <!-- <el-button
                size="mini"
                round
                class="down_links1"
                v-if="
                  scope.row.task_status !== -1 && scope.row.task_status !== -3
                "
                @click="remove(scope.row)"
                >删除</el-button
              > -->
              <!-- <el-button
                size="mini"
                round
                class="down_links"
                v-if="scope.row.task_status == 2"
                @click="feedback(scope.row)"
                >错误反馈</el-button
              > -->
              <!-- <el-button
                size="mini"
                round
                class="down_links"
                style="background: #a2c667"
                v-if="scope.row.task_status == 5 && scope.row.down_url !== '0'"
                @click="links(scope.row)"
                >相似推荐</el-button
              > -->
              <!-- <el-button
                  size="mini"
                  round
                  class="down_links"
                  style=""
                  v-if="scope.row.read_url"
                  @click="preview(scope.row)"
                  >在线阅读</el-button
                > -->
              <!-- 
                   -->
              <div
                size="mini"
                class="operate_btn"
                v-if="
                  scope.row.file_extension &&
                  scope.row.file_extension == '.PDF' &&
                  !scope.row.yicai_task_id &&
                  (scope.row.text_code == 'enDoc' ||
                    scope.row.text_code == 'PMID' ||
                    scope.row.text_code == 'PMCID' ||
                    scope.row.text_code == 'Doi')
                "
                @click="translationSub(scope.row)"
              >
                <span v-if="scope.row.sub_or_id == scope.row.id">提交中</span>
                <span v-else>提交翻译</span>
                <i
                  v-if="scope.row.sub_or_id == scope.row.id"
                  class="el-icon-loading"
                ></i>
                <img class="new_icon_css" v-if="scope.row.sub_or_id !== scope.row.id" src="../../../assets/imgs/2.2/new_2x.png" alt="">
              </div>
              <!-- <el-button
                size="mini"
                class="operate_btn"
                v-if="scope.row.yicai_task_id && scope.row.yicai_status == 1"
                @click="translationChick(scope.row)"
                >查看翻译结果
              </el-button> -->
              <div
                class="cascader_css"
                v-if="scope.row.yicai_task_id && scope.row.yicai_status == 1"
              >
                <el-cascader
                  v-model="cascaderValue"
                  :options="typeOptions"
                  placeholder="请选择下载类型"
                  @change="handleDownloadType($event, scope.row, scope.$index)"
                  size="mini"
                  class="z-index"
                  :key="scope.$index"
                  ref="cascader"
                  popper-class="train-tree"
                  placement="bottom-end"
                ></el-cascader>
                <el-button size="mini" class="operate_btn pdf_xiazai"
                  >查看翻译结果
                </el-button>
              </div>

              <!--  slot="reference" -->
              <!-- <el-popover
                placement="bottom"
                width="200"
                trigger="click"
               
              > -->
              <!--  @click="translationChick(scope.row)" -->

              <!-- </el-popover> -->
              <!--     -->
              <el-button
                size="mini"
                class="operate_btn"
                @click="translationRealTime(scope.row)"
                v-if="scope.row.yicai_task_id && scope.row.yicai_status == 0"
                >在线实时翻译
                <el-popover
                  placement="top-end"
                  title=""
                  width="300"
                  trigger="hover"
                >
                  <p>
                    在线实时翻译，点击即可预览翻译结果， 推荐使用 Google
                    Chrome、Microsoft Edge 或 360 极速浏览器查看。
                  </p>
                  <p style="margin: 7px 0 7px 0">
                    如板式存在问题，可等待翻译任务完成后查看文档翻译的最终结果。
                  </p>
                  <p>在线实时翻译不会额外消耗翻译次数。</p>

                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </el-button>
              <el-button
                size="mini"
                class="operate_btn"
                v-if="
                  scope.row.text_code == 'zhBook' &&
                  scope.row.chinese_url !== ''
                "
                @click="mores(scope.row.chinese_url)"
                >相似推荐</el-button
              >

              <el-dropdown
                @command="operateHandleCommand"
                :data-row="scope.row"
                v-if="
                  scope.row.task_status == 2 ||
                  scope.row.task_status == -2 ||
                  scope.row.task_status !== -1
                "
              >
                <!-- && scope.row.task_status !== -3 -->
                <span class="el-dropdown-link operate_btn">
                  更多
                  <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="scope.row.task_status == 2"
                    :command="
                      operateCommand(scope.$index, scope.row, 'feedback')
                    "
                    >错误反馈</el-dropdown-item
                  >
                  <!-- <el-dropdown-item
                    v-if="
                      scope.row.text_code == 'zhBook' &&
                      scope.row.chinese_url !== ''
                    "
                    :command="operateCommand(scope.$index, scope.row, 'more')"
                    >更多资源</el-dropdown-item
                  > -->
                  <el-dropdown-item
                    v-if="scope.row.task_status == -2"
                    :command="
                      operateCommand(scope.$index, scope.row, 'causeFail')
                    "
                    >查看失败原因</el-dropdown-item
                  >

                  <el-dropdown-item
                    :command="operateCommand(scope.$index, scope.row, 'del')"
                    divided
                    v-if="scope.row.task_status !== -1"
                    >删除</el-dropdown-item
                  >
                  <!-- &&
                      scope.row.task_status !== -3 -->
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-dropdown
              style="margin-left: 20px"
              placement="bottom-start"
              :hide-on-click="false"
              v-if="
                scope.row.task_status == 2 ||
                (scope.row.text_code == 'zhBook' &&
                  scope.row.chinese_url !== '')
              "
            >
              <span
                class="el-dropdown-link"
                style="color: #006eff !important; font-weight: 500 !important"
              >
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="dropdown">
                  <ul>
                    <li
                      v-if="scope.row.task_status == 2"
                      @click="feedback(scope.row)"
                    >
                      错误反馈
                    </li>
                    <li
                      v-if="
                        scope.row.text_code == 'zhBook' &&
                        scope.row.chinese_url !== ''
                      "
                      @click="mores(scope.row.chinese_url)"
                    >
                      更多资源
                    </li>
                  </ul>
                </div>
              </el-dropdown-menu>
            </el-dropdown> -->

              <!-- <el-button
              size="mini"
              round
              style="width: 90px; margin: 0 0 10px 0"
              v-if="scope.row.task_status == -1"
              disabled
              >删除</el-button
            >
            <el-button
              type="danger"
              size="mini"
              round
              style="width: 90px; margin: 0 0 10px 0"
              v-if="scope.row.task_status !== -1"
              @click="remove(scope.row)"
              >删除</el-button
            >
            <el-button
              size="mini"
              round
              style="
                background-color: #e6a23c;
                color: white;
                width: 90px;
                margin: 0 0 10px 0;
              "
              v-if="scope.row.task_status == 2"
              @click="feedback(scope.row)"
              >错误反馈</el-button
            >
            <el-button
              size="mini"
              round
              style="
                background-image: linear-gradient(to bottom, #B14A75, #B14A75);
                color: white;
                width: 90px;
                margin: 0 0 10px 0;
              "
              v-if="
                scope.row.text_code == 'zhBook' && scope.row.chinese_url !== ''
              "
              @click="mores(scope.row.chinese_url)"
              >更多结果</el-button
            > -->
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="imgs">
            <img
              src="../../../assets/imgs/zanwushuj.png"
              alt=""
              style="width: 50%"
            />
          </div>
        </template>
      </el-table>
    </div>
    <el-dialog
      title="相似推荐"
      :visible.sync="dialogVisible"
      width="580px"
      class="greater"
      @close="closeDialog"
      custom-class="dialog_file_css"
    >
      <ul style="width: 100%">
        <li v-for="(item, index) in chBook" :key="index" style="width: 100%">
          <div
            class="newBook"
            :style="
              index < chBook.length - 1 ? 'border-bottom:#E4E4E6 solid 1px' : ''
            "
          >
            <p style="justify-content: space-between">
              <span>
                <span class="span_tit">【标题】</span>
                <span class="span_con">{{ item.title }}</span> </span
              ><el-button
                v-if="item.is_exitsSSN && !item.type"
                type="primary"
                size="mini"
                @click="tabled(item, index)"
                >提交</el-button
              >
              <!-- style="
                  color: var(--white-dark-white, #fff);
                  background: #e2426b;
                  border: none !important;
                "
                class="dialog_btn" -->
              <el-button v-if="item.type" type="danger" size="mini">{{
                item.type
              }}</el-button>
              <el-button v-if="!item.is_exitsSSN" type="danger" size="mini"
                >暂无资源</el-button
              >
            </p>
            <p>
              <span class="span_tit">【ISBN】</span
              ><span class="span_con">{{ item.isbn }}</span>
            </p>
            <p>
              <span class="span_tit">【作者】</span
              ><span class="span_con">{{ item.author }}</span>
            </p>
            <p>
              <span class="span_tit">【出版时间】</span
              ><span class="span_con">{{ item.published_date }}年</span>
            </p>
            <p>
              <span class="span_tit">【出版地】</span
              ><span class="span_con">{{ item.published_address }}</span>
            </p>
            <p>
              <span class="span_tit">【内容摘要】</span
              ><span class="span_con">{{ item.abstract }}</span>
            </p>
          </div>
        </li>
      </ul>
    </el-dialog>
    <el-dialog
      @close="closeDialog"
      title="编辑反馈信息"
      :visible.sync="dialogMessage"
      width="580px"
      class="message"
      custom-class="dialog_file_css"
    >
      <!-- <div style="font-size: 18px; margin-bottom: 20px">反馈信息：</div> -->
      <el-form
        :model="anew"
        :rules="rules"
        ref="anew"
        label-width="80px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item label="任务来源">
          <el-radio-group v-model="anew.source">
            <el-radio :label="0">青梨小程序任务</el-radio>
            <el-radio :label="1">微信群任务</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="反馈原因">
          <el-radio-group v-model="anew.type" class="anew_css">
            <el-radio label="链接地址无法访问">链接地址无法访问</el-radio>
            <el-radio label="需要正式版">需要正式版</el-radio>
            <el-radio label="需要补充材料">需要补充材料</el-radio>
            <el-radio label="内容不符">内容不符</el-radio>
            <el-radio label="其他">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="反馈信息"
          prop="msg"
          v-if="anew.type == '其他'"
          style="margin-top: 24px"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="anew.msg"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          style="padding: 24px; display: flex; justify-content: flex-end"
        >
          <el-button
            style="
              color: var(---, #262729);
              background: var(---300, #f1f2f2);
              border: none !important;
            "
            class="dialog_btn"
            @click="dialogMessage = false"
            >取 消</el-button
          >
          <el-button
            style="
              color: var(--white-dark-white, #fff);
              background: #e2426b;
              border: none !important;
            "
            type="primary"
            @click="Resubmit('anew')"
            class="dialog_btn"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>

      <!-- <span slot="footer" class="dialog-footer">
        
      </span> -->
    </el-dialog>

    <div class="pag">
      <div class="bottom">
        <div class="footer">
          <!--  -->
          <!-- <el-button size="mini" type="primary" round @click="batch"
          >批量复制</el-button
        > -->

          <el-button v-if="batchs" class="footer_btn" @click="deleBatch"
            >批量删除</el-button
          >
          <el-button
            v-if="batchs"
            class="footer_btn"
            style="background-color: #e6a23c; margin-left: 24px"
            @click="onClickDown"
            >批量导出</el-button
          >
          <div
            class="allRead_btn"
            v-if="num_total"
            style="margin-left: 24px"
            @click="allRead"
          >
            <img
              class="hover-img"
              src="../../../assets/imgs/2.2/read_no.png"
              alt=""
            />
            <span>全部标记为已读</span>
          </div>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        layout="total, prev, pager, next, jumper"
        :total="totalCount"
        v-if="totalCount > 0"
        style="padding: 3px 5px"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="链接地址"
      :visible.sync="dialogVisible01"
      :width="form.url.length > 1 ? '460px' : '460px'"
      @close="closeDialog"
      custom-class="dialog_file_css"
      :show-close="false"
    >
      <div class="link_css">
        <div
          class="link_url_css"
          v-for="(item, index) in form.url"
          :key="index"
        >
          <div class="link_url">
            <span class="url_tit"
              >{{
                form.url.length > 1
                  ? "链接" + numberToChinese(index + 1)
                  : "链接"
              }}
              <span v-if="form.url.length <= 1">&ensp;</span>
            </span>
            <!-- style="width: calc(100% - 100px);" -->
            <span id="copy" class="inputText"
              ><span class="urlText" style="" @click="openLink01(item)">{{
                item
              }}</span></span
            >
            <img
              @click="copyLink01(item)"
              style="cursor: pointer"
              src="../../../assets/imgs/2.2/copy.png"
              alt=""
            />
            <!-- <el-button
              @click="openLink01(item)"
              size="mini"
              round
              style="margin: 0 0 0 10px"
              v-if="form.url.length > 1 && passType !== 2 && passType !== 1"
              >打开链接</el-button
            > -->
            <!-- <el-button
              type="primary"
              @click="copyLink01(item)"
              size="mini"
              round
              v-if="form.url.length > 1 && passType !== 2 && passType !== 1"
              >复制链接</el-button
            > -->
          </div>
        </div>
        <!--   -->
        <div class="password_css" v-if="passType == 1">
          <span class="url_tit"> 文档密码 </span>
          <span style="width: calc(100% - 72px); padding: 0 0 0 10px">
            <span class="inputText" id="pass">{{ form.password[0] }}</span
            ><span style="color: rgb(115, 171, 223); font-size: 10px"
              >（此密码为您微信 id的后4位）</span
            >
          </span>

          <!-- <i class="el-icon-document-copy" @click="copyPass"></i> -->
          <img
            @click="copyPass"
            style="cursor: pointer"
            src="../../../assets/imgs/2.2/copy.png"
            alt=""
          />
        </div>
        <!-- -->
        <div class="password_css" v-if="passType == 2">
          <span class="url_tit"> 提取码 </span>
          <span
            class="inputText"
            id="pass"
            style="width: calc(100% - 58px); padding: 0 0 0 25px"
            >{{ "form.password[0]" }}</span
          >
          <!-- <i @click="copyPass" class="el-icon-document-copy"></i> -->
          <img
            @click="copyPass"
            style="cursor: pointer"
            src="../../../assets/imgs/2.2/copy.png"
            alt=""
          />
        </div>
      </div>
      <!-- <el-form :model="form">
        <el-form-item
          :label="form.url.length > 1 ? '链接' + (index + 1) : '链接'"
          :label-width="formLabelWidth"
          v-for="(item, index) in form.url"
          :key="index"
        >
          <div class="link_url">
            <span id="copy" class="inputText" style="width: 300px"
              ><span class="urlText" style="">{{ item }}</span></span
            >
            <el-button
              @click="openLink01(item)"
              size="mini"
              round
              style="margin: 0 0 0 10px"
              v-if="form.url.length > 1 && passType !== 2 && passType !== 1"
              >打开链接</el-button
            >
            <el-button
              type="primary"
              @click="copyLink01(item)"
              size="mini"
              round
              v-if="form.url.length > 1 && passType !== 2 && passType !== 1"
              >复制链接</el-button
            >
           
          </div>
        </el-form-item>
        <el-form-item
          label="文档密码"
          :label-width="formLabelWidth"
          v-if="passType == 1"
        >
        
          <span class="inputText" id="pass">{{ form.password[0] }}</span
          ><span style="color: rgb(115, 171, 223); font-size: 10px"
            >（此密码为您微信 id的后4位）</span
          ><i class="el-icon-document-copy" @click="copyPass"></i>
        </el-form-item>
        <el-form-item
          label="提取码"
          v-if="passType == 2"
          :label-width="formLabelWidth"
        >
         
          <span class="inputText" id="pass">{{ form.password[0] }}</span
          ><i @click="copyPass" class="el-icon-document-copy"></i>
        </el-form-item>
      </el-form> -->
      <!-- <span slot="footer" class="dialog-footer" v-if="form.url.length <= 1">
        <el-button @click="openLink">打开链接</el-button>
        <el-button type="primary" @click="copyLink">复制链接</el-button>
      </span> -->
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_btn" @click="dialogVisible01 = false"
          >关闭</el-button
        >
      </span>
      <!-- <span slot="footer" class="dialog-footer">
        <div  @click="openLink">关闭</div>
      </span> -->
    </el-dialog>
    <el-dialog
      title="失败原因"
      :visible.sync="dialogVisible02"
      :width="'550px'"
      @close="closeDialog"
      custom-class="dialog_file_css"
    >
      <p style="text-indent: 2em; lin-height: 20px">{{ causeFail }}</p>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="openLink">打开链接</el-button> -->
        <el-button
          style="
            color: var(--white-dark-white, #fff);
            background: #e2426b;
            border: none !important;
          "
          class="dialog_btn"
          type="primary"
          @click="dialogVisible02 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="文档学术翻译"
      :visible.sync="dialogTranslation"
      width="580px"
      class="message"
      custom-class="dialog_file_css"
    >
      <p style="color: var(---, #6b6e73); line-height: 24px">
        提交此篇文献至文档学术翻译任务？这将消耗您的文档翻译次数。任务处理完成后，您可以点击“查看结果“按钮查看翻译结果。
      </p>
      <p
        style="
          padding: 24px 24px 0px 24px;
          color: #e2426b;
          line-height: 24px;
          text-align: center;
        "
      >
        剩余翻译次数：{{ remainderNumber }}次
      </p>
      <div style="padding: 24px; display: flex; justify-content: flex-end">
        <el-button
          style="
            color: var(---, #262729);
            background: var(---300, #f1f2f2);
            border: none !important;
          "
          class="dialog_btn"
          @click="dialogTranslation = false"
          >取 消</el-button
        >
        <el-button
          style="
            color: var(--white-dark-white, #fff);
            background: #e2426b;
            border: none !important;
          "
          type="primary"
          @click="sub_fanyi"
          class="dialog_btn"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { Message } from "element-ui";
import {
  demandCheck,
  partChBook,
  manual,
  Deletes,
  batchDelete,
  read,
  unreadNumber,
  getfileformat,
  yiCaiTask_id,
} from "@/Api/manage";
import { personalMessage, fetchMessage } from "@/Api/userMessage";
import {
  getData,
  deleteRecord,
  download,
  appletManager,
} from "@/Api/translation";
import { downloadXlsx } from "../../../utils/xlsx";
import Vue from "vue";
import { eventBus } from "../../../eventBus";
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      Height: 0,
      searchInput: "",
      type: [
        {
          book_type: "中文书",
          type: "zhBook",
          case: 0,
        },
        {
          book_type: "英文书",
          type: "enBook",
          case: 0,
        },
        {
          book_type: "DOI",
          type: "Doi",
          case: 0,
        },
        {
          book_type: "PMID",
          type: "PMID",
          case: 0,
        },
        {
          book_type: "PMCID",
          type: "PMCID",
          case: 0,
        },
        {
          book_type: "链接地址",
          type: "Url",
          case: 0,
        },
        {
          book_type: "中文文献 ",
          type: "zhDoc",
          case: 0,
        },
        {
          book_type: "英文文献",
          type: "enDoc",
          case: 0,
        },
        {
          book_type: "专利 ",
          type: "patent",
          case: 0,
        },
        {
          book_type: "标准",
          type: "standard",
          case: 0,
        },
        {
          book_type: "其他",
          type: "Other",
          case: 0,
        },
      ],
      //   type: ["中文书", "英文书", "DOI", "PMID", "PMCID"],
      buttonIndex: 0,
      buttonStates: 0,
      //   states: ["处理成功", "处理中", "待处理", "处理失败"],
      states: [
        {
          task: "处理成功",
          status: 2,
          state: 0,
        },
        {
          task: "处理中",
          status: 1,
          state: 0,
        },
        {
          task: "待处理",
          status: 0,
          state: 0,
        },
        {
          task: "处理失败",
          status: -2,
          state: 0,
        },
        {
          task: "二次处理中",
          status: 3,
          state: 0,
        },
        {
          task: "已失效",
          status: -3,
          state: 0,
        },
        {
          task: "已删除",
          status: -1,
          state: 0,
        },
      ],
      radio: 0,
      origin: 0,
      value1: "",
      value2: "",
      valueDate: [],
      time_or: parseInt(new Date().getTime() / 1000) - 60 * 60 * 24 * 3,
      // 开始时间限制
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.value2) {
            // eslint-disable-next-line max-len
            return (
              // time.getTime() > new Date(this.value2).getTime() ||
              time.getTime() >= new Date(this.value2).getTime() - 86400000
            );
          }
          return time.getTime() <= new Date(this.value2).getTime() - 86400000;
        },
      },
      // 结束时间限制
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.value1) {
            return time.getTime() < new Date(this.value1).getTime();
          }
          return time.getTime() <= new Date(this.value1).getTime() - 86400000;
        },
      },
      tableData: [],
      historyFileList: [],
      batchs: false,
      Types: ["all"],
      condition: ["all"],
      needsList: {
        page: 1,
        size: 10,
        sort_by: "time_desc",
        con_type: [],
        status: [],
        start_date: "",
        end_date: "",
        content: "",
        read: 0,
        source: 0,
      },
      start_time: "",
      end_time: "",
      currentPage: 1, // 当前页码
      totalCount: null, //总条数
      dialogVisible: false,
      chBook: [],
      partch: {},
      dialogMessage: false,
      anew: {
        task_id: "",
        msg: "",
        source: 0,
        type: "其他",
      },
      multipleSelection: [],
      rules: {
        msg: [
          { required: true, message: "请输入反馈消息", trigger: "blur" },
          { min: 3, message: "反馈信息内容至少10字符", trigger: "blur" },
        ],
      },
      intervalId: null,
      statusType: "",
      dialogVisible01: false,
      form: {
        url: [],
        password: [],
      },
      formLabelWidth: "80px",
      passType: 0,
      dialogVisible02: false,
      causeFail: "",
      num_total: 0,
      sub_or: "",
      dialogTranslation: false,
      remainderNumber: 0,
      loading: false,
      cascaderValue: null,
      typeOptions: [
        {
          value: "online",
          label: "在线预览",
        },
        {
          value: "translate",
          label: "下载译文",
          children: [
            {
              value: "pdf",
              label: "PDF版",
            },
            {
              value: "word",
              label: "Word版",
            },
          ],
        },
        {
          value: "origin",
          label: "下载原文",
          children: [
            {
              value: "pdf",
              label: "PDF版",
            },
            {
              value: "word",
              label: "Word版",
            },
          ],
        },
        {
          value: "compare",
          label: "下载对照版(除pdf中译英外)",
        },
      ],
      row_copy: {},
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    console.log("this.time_or");
    this.Height = document.documentElement.clientHeight - 170; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 170;
    };
    this.get_personalMessage();
    // 监听当前页面 显示状态
    window.addEventListener("visibilitychange", this.hanldeVisiblityChange);

    // 当页面被销毁时 移除监听
    this.$on("hook:beforeDestroy", () => {
      // console.info("ws 我被销毁了, 移除监听>>>");
      window.removeEventListener(
        "visibilitychange",
        this.hanldeVisiblityChange
      );
    });
  },
  created() {
    // let time =
    // 		console.log(time)
    let date = new Date();
    // console.log(date);
    var dateStare = new Date().setDate(new Date().getDate() - 3);
    // console.log(dateStare);
    // var date= new Date(Date.parse(date));
    let year1 = new Date(dateStare).getFullYear(); // 年
    let year = date.getFullYear(); // 年

    let month = date.getMonth() + 1; // 月
    month = month < 10 ? "0" + month : month;
    let month1 = new Date(dateStare).getMonth() + 1; // 月
    month1 = month1 < 10 ? "0" + month1 : month1;
    let day = date.getDate(); // 日
    day = day < 10 ? "0" + day : day;
    let day1 = new Date(dateStare).getDate(); // 日
    day1 = day1 < 10 ? "0" + day1 : day1;
    // console.log(day)
    // if(month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12"){
    //   if(day == "01"){
    //     day = 33
    //   }
    //   if(day == "02"){
    //     day = 33
    //   }
    // }
    // if (day == "01") {
    //   let month1 = month - 1;
    //   let day1 = 27;
    //   this.value1 = year + "-" + month1 + "-" + day1;
    // }
    this.value1 = year1 + "-" + month1 + "-" + day1;
    this.value2 = year + "-" + month + "-" + day;
    // console.log(this.value1);
    // console.log(this.value2);
    this.needsList.start_date = this.value1;
    this.needsList.end_date = this.value2;
    this.valueDate = [this.value1, this.value2];
    // this.needsList.start_date = '2024-08-29';
    // this.needsList.end_date = '2024-08-29';
    this.start_time = this.value1;
    this.end_time = this.value2;
    if (this.$route.query.origins) {
      this.origin = Number(this.$route.query.origins);
    }
    this.loading = true;
    this.demandList();
    this.dataRefreh();
  },
  methods: {
    handleDownloadType(val, row, index) {
      if (val) {
        if (val[0] == "online") {
          this.historyFileList.forEach((item, index) => {
            if (item.task_id == row.yicai_task_id) {
              this.toEastrans_online(item);
            }
          });
          this.cascaderValue = [];
          return;
        } else {
          this.handleDownLoad(row.yicai_task_id, val[0], val[1]);
        }
      }

      this.cascaderValue = [];
    },
    toEastrans_online(row) {
      //   window.open('http://eastrans.newacademic.net/#/upload', "_blank");
      // query:{id:item.task_id,pageNum:item.pageNum,from:item.langFrom,to:item.langTo}
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);
      // console.log(row);
      // return;
      let event = localStorage.getItem("code") + "|" + tmp;
      let url =
        "id=" +
        row.task_id +
        "&pageNum=" +
        row.pageNum +
        "&from=" +
        row.langFrom +
        "&to=" +
        row.langTo +
        "&code=" +
        event +
        "&type=" +
        "pdf";
      window.open(
        "http://eastrans.newacademic.net/#/transition?" + url,
        "_blank"
      );
    },
    //预览
    preview(e) {
      var task_id = e.read_url.split("task_id=");
      console.log();
      this.$router.push({
        name: "Read",
        query: {
          task_id: task_id[1],
        },
      });
    },
    closeDialog() {
      // this.needsList.page = 1;
      this.demandList();
    },
    // 选择需求状态
    beforeHandleCommand(command) {
      return {
        command: command,
      };
    },
    // 下拉框选择
    handleCommand(command) {
      // console.log(command)
      this.needsList.sort_by = command.command;
      this.needsList.page = 1;
      this.demandList();
    },
    operateHandleCommand(command) {
      console.log(command);
      if (command.command == "del") {
        this.remove(command.row);
      } else if (command.command == "feedback") {
        this.feedback(command.row);
      } else if (command.command == "more") {
        this.mores(command.row.chinese_url);
      } else if (command.command == "causeFail") {
        this.links(command.row);
      }
    },
    operateCommand(index, row, command) {
      return { index, row, command };
    },
    // 获取文件历史列表
    getFileList(tabData) {
      let data = {
        page: 1,
        name: "",
        state: 0,
      };
      getData(data)
        .then((res) => {
          console.log(res, "请求");

          if (res.data.status == 0) {
            this.historyFileList = res.data.data ? res.data.data : [];
            if (this.historyFileList.length == 0) {
            } else {
              this.historyFileList.forEach((item) => {
                // 转换来源语种
                switch (item.langFrom) {
                  case "en":
                    this.$set(item, "langFromCh", "英文");
                    break;
                  case "zh":
                    this.$set(item, "langFromCh", "中文（简体）");
                    break;
                }
                // 转换目的语种
                switch (item.langTo) {
                  case "en":
                    this.$set(item, "langToCh", "英文");
                    break;
                  case "zh":
                    this.$set(item, "langToCh", "中文（简体）");
                    break;
                }
              });
            }
          } else {
            this.historyFileList = [];
          }
          console.log(this.historyFileList, "this.historyFileList");
          tabData.forEach((item, index) => {
            let filteredTasks = this.historyFileList.filter(
              (task) => task.task_id === item.yicai_task_id
            );
            console.log(filteredTasks);
            if (filteredTasks.length > 0) {
              // item.yicai_status = filteredTasks[0].msg
              this.$set(item, "yicai_status", filteredTasks[0].task_status);
            }
          });
          this.tableData = tabData;
          this.loading = false;
          console.log(this.tableData);
        })
        .catch((err) => {
          this.tableData = tabData;
          this.loading = false;
          // this.$message.error(err);
        });
    },
    //   需求列表
    demandList() {
      // this.needsList.con_type = this.Types;
      // if (this.condition.length > 0) {
      if (this.Types.length > 1) {
        this.needsList.con_type = this.Types.filter((item) => item !== "all");
      } else {
        this.needsList.con_type = this.Types;
      }
      if (this.condition.length > 1) {
        this.needsList.status = this.condition.filter((item) => item !== "all");
      } else {
        this.needsList.status = this.condition;
      }
      if (
        this.needsList.status.includes(1) &&
        !this.needsList.status.includes(6)
      ) {
        this.needsList.status.push(6); // 添加 6
      }
      // } else {
      //   this.needsList.status = ["all"];
      // }
      let que = {
        uc: localStorage.getItem("code"),
      };
      this.currentPage = this.needsList.page;
      // this.loading = true
      demandCheck(this.needsList, que)
        .then((res) => {
          // console.log(res);

          // if (this.needsList.read == 2) {
          //   let read_or = [];
          //   res.data.data.data.forEach((item, index) => {
          //     if (item.task_status == 2 || item.task_status == -2) {
          //       read_or.push(item);
          //     }
          //   });
          //   this.tableData = read_or;
          //   this.totalCount = read_or.length;
          // } else {

          let result_data = [];
          res.data.data.data.forEach((item, index) => {
            if (
              (item.task_status == 2 || item.task_status == 5) &&
              item.down_url
            ) {
              // console.log(item.down_url);
              // console.log(this.disposeUrl(item));
              item.down_file = this.disposeUrl(item);
            } else {
              item.down_file = [];
            }

            let url_or = item.down_url.includes("下载地址");
            // console.log(url_or);
            if (url_or) {
              let parts = item.down_url.split("下载地址");

              // 使用正则表达式匹配每个部分中的URL
              let urls_ = parts
                .map((part) => part.match(/https?:\/\/[^\s]+/g))
                .filter(Boolean)
                .flat();

              console.log(urls_);
              item.urls = urls_;
            } else {
              item.urls = item.down_url.split(",");
            }
            if (
              (item.task_status == 2 || item.task_status == 5) &&
              item.down_url !== "" &&
              item.down_url !== "0" &&
              item.urls[0] &&
              item.urls &&
              item.urls.length > 0 &&
              item.text_name !== "中文书" &&
              item.text_name !== "英文书"
            ) {
              item.urls.forEach((item_url, index_url) => {
                // console.log(item_url);

                if (item_url) {
                  result_data.push({
                    id: item.id,
                    url: item_url,
                  });
                }
              });
            }
          });
          this.totalCount = res.data.data.total;
          // }
          // this.statusType = res.data.data.data_from;
          console.log("result_dataresult_dataresult_data", result_data);
          if (result_data.length > 0) {
            this.isFile(result_data, res.data.data.data);
          } else {
            // this.tableData = res.data.data.data;
            this.getFileList(res.data.data.data);
            this.notNumbers();
            // this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    disposeUrl(row) {
      // console.log(row)
      if (row.task_status !== -2) {
        // this.dialogVisible01 = true;
        let formUrl = [];
        if (row.down_url.indexOf("文档密码：") !== -1) {
          let pass = row.down_url.split("文档密码：");
          let obj = {
            url: pass[0],
            password: pass[1].slice(0, 4),
            passType: 1,
          };
          formUrl.push(obj);
        } else if (row.down_url.indexOf("提取码：") !== -1) {
          let pass = row.down_url.split("提取码：");
          let obj = {
            url: pass[0],
            password: pass[1].slice(0, 4),
            passType: 2,
          };
          formUrl.push(obj);
        } else {
          this.passType = 0;
          if (row.down_url.indexOf(",") !== -1) {
            let urlArray = row.down_url.split(",");
            // this.form.url = [];
            urlArray.forEach((item, index) => {
              let urls_ = "";
              urls_ = item.replace(/\n/g, "      ");
              // console.log(urls_);
              if (urls_.indexOf("      ") !== -1) {
                let urlList_ = urls_.split("      ");
                console.log(urlList_);
                urlList_.forEach((item_) => {
                  let url_obj = {
                    url: "",
                    passType: 0,
                  };
                  if (item_.indexOf("http:") !== -1) {
                    url_obj.url = item_.substring(item_.lastIndexOf("http:"));
                    formUrl.push(url_obj);
                    // this.form.url.push(
                    //   item_.substring(item_.lastIndexOf("http:"))
                    // );
                  }
                });
                // console.log(urlList_);
              } else {
                // this.form.url.push(item);
                let url_obj = {
                  url: item,
                  passType: 0,
                };
                formUrl.push(url_obj);
              }
            });
          } else {
            let urls = "";
            urls = row.down_url.replace(/\n/g, "      ");
            console.log(urls);
            if (urls.indexOf("      ") !== -1) {
              let urlList = urls.split("      ");
              console.log(urlList);

              urlList.forEach((item) => {
                let url_obj = {
                  url: "",
                  passType: 0,
                };
                if (item.indexOf("http:") !== -1) {
                  // this.form.url.push(item.substring(item.lastIndexOf("http:")));
                  url_obj.url = item.substring(item.lastIndexOf("http:"));
                  formUrl.push(url_obj);
                }
              });
              console.log(urlList);
            } else {
              // this.form.url = [];
              // this.form.url.push(row.down_url);
              let url_obj = {
                url: row.down_url,
                passType: 0,
              };
              formUrl.push(url_obj);
            }
          }
        }
        return formUrl;
        // window.open(row.down_url, "_blank");
      }
    },
    isFile(data, tabData) {
      console.log(data);
      getfileformat({
        result_data: data,
      })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            res.data.data.forEach((item) => {
              const matchingItem = tabData.find(
                (item_list) => item_list.id === item.id
              );
              if (matchingItem) {
                // 将匹配项的 is_bigfile 赋值给 arrayB
                if (item.file_extension) {
                  // matchingItem.file_extension = item.file_extension;
                  this.$set(
                    matchingItem,
                    "file_extension",
                    item.file_extension
                  );
                }
              }
            });
            //
            console.log("this.tableData = tabData;");
            this.loading = false;
            this.getFileList(tabData);
            this.notNumbers();
            // console.log(this.tableData);
          } else {
            this.tableData = tabData;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.tableData = tabData;
          this.loading = false;
        });
    },
    // 序号
    indexMethod(index) {
      return index + 1;
    },
    onSubmit() {
      this.needsList.content = this.searchInput;
      this.reset_search();
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
      // console.log('submit!');
    },
    search_type_() {
      // console.log(this.Types);
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    // 筛选类型
    search_type(item, i) {
      // console.log(item, i)
      // this.type[i].case = 1;
      if (this.type[i].case == 0) {
        this.type[i].case = 1;
        // console.log(this.type);
        let index = this.Types.indexOf("all");
        if (index > -1) {
          //大于0 代表存在，
          this.Types.splice(index, 1); //存在就删除
        }
        this.Types.push(item.type);
        // console.log(this.Types);
      } else {
        this.type[i].case = 0;
        let index = this.Types.indexOf(item.type);
        if (index > -1) {
          //大于0 代表存在，
          this.Types.splice(index, 1); //存在就删除
        }
        // console.log(this.Types);
        if (this.Types.length == 0) {
          this.Types = ["all"];
        }
      }
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    search_states_() {
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    // 筛选状态
    search_states(item, i) {
      //
      //   console.log(this.states[i].state)
      //   console.log(typeof this.states[i].state)
      // this.condition = [];

      if (this.states[i].state == 0) {
        this.states[i].state = 1;
        let index = this.condition.indexOf("all");
        if (index > -1) {
          //大于0 代表存在，
          this.condition.splice(index, 1); //存在就删除
        }
        this.condition.push(item.status);
        // console.log(this.states);
      } else {
        this.states[i].state = 0;
        let index = this.condition.indexOf(item.status);
        if (index > -1) {
          //大于0 代表存在，
          this.condition.splice(index, 1); //存在就删除
        }
        if (this.condition.length == 0) {
          this.condition = ["all"];
        }
      }
      // console.log(this.condition);
      this.needsList.page = 1;
      this.demandList();
    },
    // 是否已读
    radios(label) {
      // console.log(label)
      // if (label == 1) {
      //   this.needsList.read = 1;
      // } else if (label == 2) {
      //   this.needsList.read = 0;
      // } else {
      //   this.needsList.read = null;
      // }
      this.loading = true;
      this.needsList.read = label;
      this.needsList.page = 1;
      this.demandList();
    },
    origins(label) {
      this.needsList.source = label;
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    // 重置
    reset() {
      this.type.forEach((item) => {
        item.case = 0;
      });
      this.states.forEach((item) => {
        item.state = 0;
      });
      this.condition = [];
      this.Types = ["all"];
      this.needsList.start_date = this.start_time;
      this.needsList.end_date = this.end_time;
      this.value1 = this.start_time;
      this.value2 = this.end_time;
      this.needsList.page = 1;
      this.needsList.read = 0;
      this.radio = 0;
      this.origin = 0
      this.searchInput = ''
      this.needsList.content = ''
      this.needsList.source = 0;
      this.loading = true;
      this.demandList();
    },
    reset_search() {
      this.type.forEach((item) => {
        item.case = 0;
      });
      this.states.forEach((item) => {
        item.state = 0;
      });
      this.condition = [];
      this.Types = ["all"];
      this.needsList.start_date = this.start_time;
      this.needsList.end_date = this.end_time;
      this.value1 = this.start_time;
      this.value2 = this.end_time;
      this.needsList.page = 1;
      this.needsList.read = 0;
      this.radio = 0;
      this.origin = 0
      
      this.needsList.source = 0;
      this.loading = true;
      this.demandList();
    },
    //显示问题详情列表
    contentDetail(row) {
      // console.log(row);
    },
    //显示标题详情
    titleDetail(row) {
      // console.log(row);
    },
    // 多选  批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
      if (val.length > 0) {
        this.batchs = true;
      } else {
        this.batchs = false;
      }
    },
    batch() {
      this.batchs = false;
    },
    // 导出exls表格
    onClickDown() {
      let datalist = [];
      datalist.push(["文书类型", "标题", "下载地址"]);
      this.multipleSelection.forEach((item) => {
        datalist.push([item.text_name, item.text, item.down_url]);
      });
      downloadXlsx(datalist, "需求数据统计.xlsx");
      // this.multipleSelection = []
      this.$refs.multipleTable.clearSelection();
      this.batchs = false;
    },
    // 批量删除
    deleBatch() {
      this.$confirm("确定删除勾选的数据？", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "cssCancel",
        confirmButtonClass: "cssConfirm",
      })
        .then(() => {
          let delete_id = [];
          this.loading = true;
          this.multipleSelection.forEach((item) => {
            // delete_id.push(item.task_id);
            let md = {
              task_id: item.task_id,
              source: item.data_from == "ql_table" ? 0 : 1,
            };
            Deletes(md)
              .then((res) => {
                // console.log(res);

                this.demandList();
                // this.$message({
                //   message: res.data.msg,
                //   type: "success",
                // });
              })
              .catch((err) => {
                console.log(err);
              });
          });
          // this.multipleSelection = []
          this.$refs.multipleTable.clearSelection();
          this.$message({
            message: "删除成功",
            type: "success",
          });

          this.batchs = false;
        })
        .catch(() => {});
    },
    change(type) {
      // console.log(i);start_date
      // console.log(this.value1); // 确定时间范围之后触发的函数
      if (type == "start") {
        this.needsList.start_date = this.value1;
      }
      if (type == "end") {
        this.needsList.end_date = this.value2;
      }
      this.needsList.page = 1;
      this.demandList();
    },
    // 链接地址
    links(row) {
      // console.log(row.read);
      // row.read = -2
      // row.task_status = -2
      if (row.read == "0") {
        let id = {
          task_id: [row.task_id],
          source: row.data_from == "ql_table" ? 0 : 1,
        };
        read(id)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        this.demandList();
      }
      // console.log(row.down_url)
      // row.down_url =
      //   "标题:  __中国禁毒历程_p570_.pdf\nURL:  http://cb1.new66.net/#/index?filename=601b465dad0a4d3fc845ef462f30905004876eefa702853291695295ba9ba79853db28bc0821439026bc6d1c65950250e45303bc3c021279529cd0af36f28b5c\n======================\n";
      // row.down_url =
      //   "http://s.dic.cool/S/dXGB8BXM文档密码：1m22（此密码为您微信 id的后4位）";
      // console.log(row.down_url);
      // if (row.text_code == "enDoc" || row.text_code == "enBook") {
      //   if (row.down_url.indexOf(",") !== -1) {
      //     let urlArray = row.down_url.split(",");
      //     this.form.url = [];
      //     urlArray.forEach((item, index) => {
      //       this.form.url.push(item);
      //     });
      //   } else {
      //     this.form.url = [row.down_url];
      //   }
      // } else
      if (row.task_status !== -2) {
        this.dialogVisible01 = true;

        if (row.down_url.indexOf("文档密码：") !== -1) {
          // alert("Hi,Tara");
          this.passType = 1;
          let pass = row.down_url.split("文档密码：");
          // this.form.url = pass[0];
          this.form.url.push(pass[0]);
          console.log(pass);
          this.form.password.push(pass[1].slice(0, 4));
        } else if (row.down_url.indexOf("提取码：") !== -1) {
          // alert("Hi,Tara");
          this.passType = 2;
          let pass = row.down_url.split("提取码：");
          // this.form.url = pass[0];
          this.form.url.push(pass[0]);
          console.log(pass);
          // this.form.password = pass[1].slice(0, 4);
          this.form.password.push(pass[1].slice(0, 4));
        }
        // else if (row.down_url.indexOf("\n") !== -1) {
        //   // alert("Hi,Tara");
        //   // this.passType = 2;
        //   let pass = row.down_url.split("");
        //   let urlList = row.down_url.split('\n')
        //   urlList.forEach((item)=>{
        //     if (item.indexOf("http:") !== -1){
        //       this.form.url = item.substring(item.lastIndexOf("http:"));
        //     }
        //   })
        //   console.log(urlList)
        //   // this.form.url = pass[0];
        //   // console.log(pass);
        //   // this.form.password = pass[1].slice(0, 4);
        // }
        else {
          this.passType = 0;
          console.log();
          if (row.down_url.indexOf(",") !== -1) {
            let urlArray = row.down_url.split(",");
            this.form.url = [];
            urlArray.forEach((item, index) => {
              let urls_ = "";
              urls_ = item.replace(/\n/g, "      ");
              console.log(urls_);
              if (urls_.indexOf("      ") !== -1) {
                // alert("Hi,Tara");
                // this.passType = 2;
                // let pass = row.down_url.split("");
                let urlList_ = urls_.split("      ");
                console.log(urlList_);

                urlList_.forEach((item_) => {
                  if (item_.indexOf("http:") !== -1) {
                    this.form.url.push(
                      item_.substring(item_.lastIndexOf("http:"))
                    );
                    // this.form.url = item.substring(item.lastIndexOf("http:"));
                  }
                });
                console.log(urlList_);
                // this.form.url = pass[0];
                // console.log(pass);
                // this.form.password = pass[1].slice(0, 4);
              } else {
                this.form.url.push(item);
              }
            });
          } else {
            let urls = "";
            urls = row.down_url.replace(/\n/g, "      ");
            console.log(urls);
            if (urls.indexOf("      ") !== -1) {
              // alert("Hi,Tara");
              // this.passType = 2;
              // let pass = row.down_url.split("");
              let urlList = urls.split("      ");
              console.log(urlList);
              this.form.url = [];
              urlList.forEach((item) => {
                if (item.indexOf("http:") !== -1) {
                  this.form.url.push(item.substring(item.lastIndexOf("http:")));
                  // this.form.url = item.substring(item.lastIndexOf("http:"));
                }
              });
              console.log(urlList);
              // this.form.url = pass[0];
              // console.log(pass);
              // this.form.password = pass[1].slice(0, 4);
            } else {
              this.form.url = [];
              this.form.url.push(row.down_url);
            }
          }
          return;
        }

        // window.open(row.down_url, "_blank");
      }
      if (row.task_status == -2) {
        this.dialogVisible02 = true;
        if (row.error_msg == "") {
          this.causeFail = "暂未找到失败原因！！";
          // this.$message("工作人员没有填写失败原因哦！！");
        } else {
          this.causeFail = row.error_msg;
          // this.$message.error("失败原因：" + row.error_msg);
        }
      }
    },
    disposeRead(row) {
      if (row.read == "0") {
        let id = {
          task_id: [row.task_id],
          source: row.data_from == "ql_table" ? 0 : 1,
        };
        read(id)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        this.demandList();
      }
    },
    downFileOpen(item, row, i) {
      console.log(row);
      this.disposeRead(row);
      // return
      if (item.passType == 0 && i <= 1) {
        window.open(item.url, "_blank");
      } else {
        // const urlArray = data.map(item => item.url);
        this.form.url = row.down_file.map((item) => item.url);
        this.form.password = row.down_file.map((item) => item.password);
        this.passType = row.down_file[0].passType;
        this.dialogVisible01 = true;
      }
    },
    // 打开链接
    openLink() {
      window.open(this.form.url[0], "_blank");
      this.dialogVisible01 = false;
    },
    openLink01(links) {
      window.open(links, "_blank");
    },
    // 复制链接
    copyLink() {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = this.form.url[0];
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 复制链接
    copyLink01(item) {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = item;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 复制密码
    copyPass() {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = this.form.password[0];
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    notNumbers() {
      unreadNumber()
        .then((res) => {
          // console.log(res);
          // if (res.data.data.num > 0) {
          // this.$emit("getTreeData", res.data.data.num);
          // }
          if (res.data.data.num > 0) {
            this.num_total = res.data.data.num;
            console.log(this.num_total);
          } else {
            this.num_total = 0;
          }
          eventBus.$emit("updateNum", res.data.data.num);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 更多结果
    mores(link) {
      // this.dialogVisible = true;
      // this.chBook = [
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      // ];
      this.$http
        .get(link, {})
        .then((res) => {
          // console.log(res);
          if (res.data.error_code == 0) {
            this.chBook = res.data.data;
            if (this.chBook.length > 0) {
              this.dialogVisible = true;
            } else {
              Message({
                message: "暂无更多数据！！",
                type: "warning",
              });
            }
            // Message({
            //   message: res.data.msg,
            //   type: "success",
            // });
          }
          if (res.data.error_code == 1) {
            Message({
              message: res.data.msg,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交中文书
    tabled(part, index) {
      // console.log(part);
      this.partch = {
        demand: [
          {
            id: 1,
            type_code: "zhBook",
            text: part.title,
            original_text: "<b>" + part.title + "</b>",
            priority: "一般",
            title: part.title,
            ssn: part.id,
          },
        ],
      };
      partChBook(this.partch)
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            if (res.data.data[1].msg == "需求提交成功") {
              this.$message({
                message: res.data.data[1].msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.data[1].msg,
                type: "warning",
              });
            }

            Vue.set(this.chBook[index], "type", res.data.data[1].msg);
            console.log(this.chBook);
            // this.dialogVisible = false;
            // this.demandList();
          }
          // if (res.data.error_code == 1) {
          //   this.$message({
          //     message: res.data.msg,
          //     type: "warning",
          //   });
          // }
        })
        .catch((err) => {
          // console.log(err);
          // this.$message({
          //   message: "提交失败",
          //   type: "warning",
          // });
        });
    },
    // 错误反馈
    feedback(row) {
      this.anew.msg = "";
      // console.log(id);

      if (row.data_from == "ql_table") {
        this.anew.source = 0;
      } else {
        this.anew.source = 1;
      }
      this.anew.type = "链接地址无法访问";
      this.dialogMessage = true;
      this.anew.task_id = row.task_id;
    },
    // 重新提交任务
    Resubmit(anew) {
      this.anew.msg = this.anew.msg.replace(/\s*/g, ""); // 去除字符串内所有的空格
      // console.log(this.anew);
      // return;
      this.$refs[anew].validate((valid) => {
        if (valid) {
          // console.log(this.anew)
          // return
          let anew_ = {
            task_id: this.anew.task_id,
            source: this.anew.source,
            msg: this.anew.type == "其他" ? this.anew.msg : this.anew.type,
          };
          // console.log(anew_)
          // return
          manual(anew_)
            .then((res) => {
              // console.log(res);
              // if (res.data.error_code == 1) {
              //   this.$message({
              //     message: res.data.msg,
              //     type: "warning",
              //   });
              // }
              if (res.data.error_code == 0) {
                this.dialogMessage = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              }
              this.demandList();
            })
            .catch((err) => {
              // console.log(err);
              // this.$message({
              //   message: err,
              //   type: "warning",
              // });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 单个删除需求
    remove(row) {
      // console.log(id);
      this.$confirm("是否确定删除该条信息", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "cssCancel",
        confirmButtonClass: "cssConfirm",
      })
        .then(() => {
          let md5 = {
            task_id: row.task_id,
            source: row.data_from == "ql_table" ? 0 : 1,
          };
          // console.log(md5);
          this.loading = true;
          Deletes(md5)
            .then((res) => {
              // console.log(res);
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.demandList();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    allRead() {
      this.$confirm("是否确定全部标记为已读", {
        confirmButtonText: "确认标记",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "cssCancel",
        confirmButtonClass: "cssConfirm",
      })
        .then(() => {
          let id = {
            task_id: ["all"],
            source: 0,
          };
          read(id)
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });

          this.demandList();
        })
        .catch(() => {});
    },

    // 分页跳转
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.needsList.page = val;
      this.loading = true;
      this.demandList();
      // this.currentPage = val;
      // window.scrollTo(0, 0);
      // this.loadNode = true;
      // this.loading_box = true;
      // this.caseList();
    },
    // 每页显示条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      // this.pageSize = val;
      // this.loadNode = true;
      // this.loading_box = true;
      // this.caseList();
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        // console.log("刷新" + new Date());
        this.demandList(); //加载数据函数
      }, 30000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    hanldeVisiblityChange() {
      if (document.visibilityState === "hidden") {
        // console.log("ws  hidden>>>关闭定时器");
        this.clear();
      } else if (document.visibilityState === "visible") {
        // console.log("ws  visible>>>启动定时器");
        this.dataRefreh();
      }
    },
    tableRowClassName({ row, rowIndex }) {
      // 根据行索引或行数据动态返回 CSS 类名
      // console.log(row)

      if (row.read == 0) {
        // return "read_css"
        return "";
      }
    },
    getCellStyle({ row, column }) {
      // console.log(row, column);
      if (
        column.type === "selection" &&
        row.read == 0 &&
        (row.task_status == 2 || row.task_status == -2 || row.task_status == -3)
      ) {
        // return "half-border-left";
        return {
          // borderLeft: "4px solid #E2426B", // 红色背景
          // color: "#d93025", // 红色字体
        };
      }
      // return {};
      return "";
    },
    getCellClassName({ row, column }) {
      if (
        column.type === "selection" &&
        row.read == 0 &&
        (row.task_status == 2 || row.task_status == -2 || row.task_status == -3)
      ) {
        return "half-border-left"; // 动态添加类名
      }
      return "";
    },

    numberToChinese(num) {
      const map = {
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
        7: "七",
        8: "八",
        9: "九",
        10: "十",
      };
      return map[num] || num; // 如果不在范围内，返回原值
    },
    // 获取
    get_personalMessage() {
      console.log('aaaaaa')
      if (!sessionStorage.getItem("mes")) {
        return;
      }
      let mes_copy = JSON.parse(sessionStorage.getItem("mes"));
      console.log('aaaaaa',mes_copy)
      appletManager({
        user_code: mes_copy.code,
      })
        .then((res) => {
          console.log(res.data.data);
          if (res.data.data && res.data.data.info) {
            this.remainderNumber = res.data.data.info[0].surplus;
          }
          // sessionStorage.setItem("mes", JSON.stringify(res.data.data));
          // if (res.data.data.limit_num_dict) {
          //   let num = res.data.data.limit_num_dict.yicai_extend;
          //   const match = num.match(/\d+/); // 匹配字符串中的数字
          //   this.remainderNumber = match ? parseInt(match[0], 10) : 0;
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async processUrls(row) {
      for (const url of row.urls) {
        try {
          let pdf_name = row.title + "_" + new Date().getTime() + ".pdf";
          const res = await axios.get("https://journal.codo.my/download_file", {
            params: { url },
            responseType: "blob", // 设置返回类型为 blob，用于处理文件流
          });
          console.log(res);
          if (res && res.data) {
            // 文件流获取成功，处理后续逻辑
            const text = await res.data.text();
            let parsedData;
            try {
              parsedData = JSON.parse(text); // 尝试解析为 JSON
            } catch (error) {
              parsedData = null; // 如果解析失败，说明是正常的文件流
            }

            if (parsedData && parsedData.code === 0) {
              console.error("文件请求失败:", parsedData.msg);
              continue; // 跳过当前 URL，处理下一个
            }

            // 如果没有错误，处理文件
            console.log("文件流正常:", res.data);
            console.log("文件流获取成功:", url);

            const blob = new Blob([res.data]);
            const file = new File([blob], pdf_name, { type: blob.type });

            // 准备 FormData，用于上传文件
            const formData = new FormData();
            formData.append("file_obj", file);
            formData.append("langFrom", "en");
            formData.append("langTo", "zh");
            formData.append("file_num", 1);

            // 上传文件
            const uploadResponse = await axios.post(
              "https://eastrans.newacademic.net/api/translate/upload/",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  USID: window.localStorage.getItem("token_USID"),
                },
              }
            );

            console.log("文件上传成功:", uploadResponse.data);

            if (uploadResponse.data.status == 0) {
              const task_id = uploadResponse.data.data.task_id_list[0];
              const obg = {
                task_id: row.task_id,
                yicai_task_id: task_id,
                source: row.data_from == "ql_table" ? 1 : 2,
              };
              this.relevancyId(obg);
            } else {
              this.$message.error(uploadResponse.data.msg);
            }

            // this.sub_or = "";
            this.error_dispose(row);
            return; // 成功后终止循环和函数
          }
        } catch (error) {
          console.error(`文件流获取失败，尝试下一个 URL: ${url}`, error);
        }
      }

      // 如果所有 URL 都失败，显示错误消息
      // console.error("所有 URL 请求失败，无法处理文件流");
      // this.$message.error("所有文件请求失败，无法上传");
      this.$message.error("PDF文件请求失败，暂不支持翻译");
      // this.sub_or = "";
      this.error_dispose(row);
    },
    error_dispose(row) {
      const targetObject = this.tableData.find((item) => item.id === row.id);
      if (targetObject) {
        targetObject.sub_or_id = "";
        this.$set(targetObject, "sub_or_id", "");
        this.$set(row, "sub_or_id", "");
        // this.tableData = [...this.tableData];
        this.tableData = JSON.parse(JSON.stringify(this.tableData));
      }
    },
    sub_fanyi() {
      let row = this.row_copy;
      if (row.sub_or_id) {
        return;
      } else {
        const targetObject = this.tableData.find((item) => item.id === row.id);
        if (targetObject) {
          targetObject.sub_or_id = targetObject.id;
          this.$set(targetObject, "sub_or_id", targetObject.id);
          this.$set(row, "sub_or_id", targetObject.id);
          // this.tableData = [...this.tableData];
          this.tableData = JSON.parse(JSON.stringify(this.tableData));
        } else {
          this.dialogTranslation = false;
          return;
        }
      }
      this.dialogTranslation = false;
      console.log(row);
      this.processUrls(row);
      
    },
    // 提交翻译
    translationSub(row) {
      if (this.remainderNumber <= 0) {
        this.$message({
          message: "您的文档翻译次数已达上限！",
          type: "warning",
        });
        return;
      } else {
        this.row_copy = row;
        this.dialogTranslation = true;

        return;
      }
      if (row.sub_or_id) {
        return;
      } else {
        const targetObject = this.tableData.find((item) => item.id === row.id);
        if (targetObject) {
          targetObject.sub_or_id = targetObject.id;
          this.$set(targetObject, "sub_or_id", targetObject.id);
          this.$set(row, "sub_or_id", targetObject.id);
          // this.tableData = [...this.tableData];
          this.tableData = JSON.parse(JSON.stringify(this.tableData));
        } else {
          return;
        }
      }

      console.log(row);

      // if (this.sub_or) {
      //   return;
      // } else {
      //   this.sub_or = row.id;
      // }
      this.processUrls(row);
      return;
      let pdf_name = row.title + "_" + new Date().getTime() + ".pdf";
      // https://journal.codo.my/download_file?url=http://s.dic.cool/S/zo60uxLe
      axios
        .get("https://journal.codo.my/download_file", {
          params: {
            url: row.urls[0],
          },

          responseType: "blob", // 设置返回类型为 blob，用于处理文件流
        })
        .then((res) => {
          console.log(res);
          return;
          let arrayBufferData = new Uint8Array(res.data);
          console.log(arrayBufferData);

          const blob = new Blob([res.data]);
          const file = new File([blob], pdf_name, {
            type: blob.type,
          });

          // 2. 准备 FormData，用于上传文件
          const formData = new FormData();
          formData.append("file_obj", file);
          formData.append("langFrom", "en");
          formData.append("langTo", "zh");
          formData.append("file_num", 1);
          return axios.post(
            "https://eastrans.newacademic.net/api/translate/upload/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                USID: window.localStorage.getItem("token_USID"),
              },
            }
          );
        })
        .then((uploadResponse) => {
          console.log("文件上传成功:", uploadResponse);
          if (uploadRespons && uploadRespons !== undefined) {
            if (uploadResponse.data.status == 0) {
              uploadResponse.data.data.task_id_list[0];
              let obg = {
                task_id: row.task_id,
                yicai_task_id: uploadResponse.data.data.task_id_list[0],
                source: row.data_from == "ql_table" ? 1 : 2,
              };
              this.relevancyId(obg);
            } else {
              this.$message.error(uploadResponse.data.msg);
            }
          }

          this.sub_or = "";
        })
        .catch((err) => {
          console.error("操作失败:", err);
          this.sub_or = "";
        });
    },
    relevancyId(obg) {
      yiCaiTask_id(obg)
        .then((res) => {
          console.log(res);
          this.remainderNumber = this.remainderNumber - 1;

          this.demandList();
        })
        .catch((err) => {});
    },
    // 查看翻译结果
    translationChick(row) {
      this.historyFileList.forEach((item, index) => {
        if (item.task_id == row.yicai_task_id) {
          this.handleDownLoad(item.task_id);
        }
      });
    },
    // 下载操作
    handleDownLoad(id, version, type) {
      let down_id = {
        task_id: "['" + id + "']",
        version: version,
        type: type,
      };
      download(down_id)
        .then((res) => {
          if (res.data.status == 0) {
            if (res.data.data[0].status == 0) {
              window.open(res.data.data[0].url, "_blank");
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 在线实时翻译
    translationRealTime(row) {
      this.historyFileList.forEach((item, index) => {
        if (item.task_id == row.yicai_task_id) {
          this.toEastrans(item);
        }
      });
    },
    toEastrans(row) {
      //   window.open('http://eastrans.newacademic.net/#/upload', "_blank");
      // query:{id:item.task_id,pageNum:item.pageNum,from:item.langFrom,to:item.langTo}
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);

      let event = localStorage.getItem("code") + "|" + tmp;
      let url =
        "id=" +
        row.task_id +
        "&pageNum=" +
        row.pageNum +
        "&from=" +
        row.langFrom +
        "&to=" +
        row.langTo +
        "&code=" +
        event +
        "&type=" +
        "realTime";
      console.log("http://eastrans.newacademic.net/#/transition?" + url);
      window.open(
        "http://eastrans.newacademic.net/#/transition?" + url,
        "_blank"
      );
      // window.open(
      //   "http://192.168.66.34:9000/#/transition?" + url,
      //   "_blank"
      // );
      //   window.open(" http://192.168.66.34:9001/#/transition?" + url, "_blank");
    },
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
  },
};
</script>

<style lang='less' scoped>
.management_box {
  // height: calc(100vh - 210px);
  // overflow-x: hidden;
  // overflow-y: scroll;
  // background: white;
  // position: relative;
  // min-height: 727px;
  // padding: 30px 10%;
  // display: flex;
  // justify-content: center;
  // .main_box{
  //   max-width: 1500px;
  // }
  font-family: PingFangSC-Regular, PingFang SC;
  .header {
    display: flex;
    // justify-content: space-between;
    // width: 80%;
    max-width: 1400px;
    // margin: auto;
    min-width: 600px;
    height: 50px;
    line-height: 50px;
    h2 {
      font-size: 18px;
      // border-left: rgb(243, 102, 102) 3px solid;
      // padding-left: 5px;
    }
    .input {
      // width: 380px;
      display: flex;
      align-items: center;
      line-height: 50px;
      .el-input {
        /deep/ .el-input__inner {
          width: 360px;
          height: 36px;
          line-height: 36px;
          border: 1px solid var(--Gray-200, #e7e5e4);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          // border-radius: 15px;
        }
      }
      .el-button {
        margin: 0 0 0 16px;
        border-radius: 4px;
        // height: 36px;
        // line-height: 36px;
        border: 1px solid #e2426b;
        background: #e2426b;
        padding: 10px 23px;
      }
    }
    .el-button--mini {
      width: 90px;
    }
  }
  .search_field {
    max-width: 1400px;
    // margin: auto;
    color: var(---, #262729);
    .sifts {
      display: flex;
      margin: 15px 0;

      .sifts_tit {
        width: 75px;
        line-height: 20px;
        font-weight: 600;
      }
      .choose {
        // width: 800px;
        width: calc(100% - 100px);
        .el-button--mini {
          // width: 90px;
          // margin: 0 10px 10px 0;
        }
        .reset_css {
          background: #e2426b;
          border-radius: var(---4, 4px);
          background: #e2426b;
          border-color: #e2426b;
          color: var(---Color-7, #fff);
          line-height: 22px;
          display: flex;
          padding: var(---4, 4px) 24px;
          justify-content: center;
          align-items: center;
          gap: var(---4, 4px);
        }
        span {
          font-weight: normal;
        }
        .bgcolor {
          background-color: #f5f3ff;
          color: #aaa;
        }
        .chosen {
          color: #ffffff;
          background-image: linear-gradient(to bottom, #b14a75, #b14a75);
        }
        /deep/ .el-radio__label {
          color: var(---, #8d8f94);
        }
        /deep/ .el-radio__input.is-checked + .el-radio__label {
          color: var(---, #262729);
        }
        /deep/ .el-radio__input.is-checked .el-radio__inner {
          border-color: #e2426b;
          background: #e2426b;
        }
        /deep/ .el-range-separator {
          width: 25px;
        }
      }
      .checkbox_group_css {
        /deep/ .el-checkbox__original {
          display: none;
        }
        /deep/ .el-checkbox__label {
          color: var(---, #8d8f94);
        }
        /deep/ .is-checked {
          .el-checkbox__label {
            color: var(---, #262729);
          }
          .el-checkbox__inner {
            background: #e2426b !important;
            border-color: #e2426b;
          }
        }
      }
    }
  }
  .tables {
    // max-width: 1400px;

    margin: auto;
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #e2426b;
      border-color: #e2426b;
    }
    /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #e2426b;
      border-color: #e2426b;
    }
    /deep/ .el-checkbox__inner:hover {
      border-color: #e2426b;
    }
    /deep/ .el-table .el-table__cell{
      padding: 0 !important;
    }
    /deep/ .el-table .el-table__cell > .cell{
      padding: 12px 10px 12px 10px !important;
    }
    /deep/ .el-table th.el-table__cell > .cell {
      font-weight: 600;
      color: var(--Gray-Gray-500, #a1a5b7);
      
    }
    .row_text_css {
      // width: calc(100% - 130px);
      max-width:240px;
      display: inline-block;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .status_btn_css {
      display: flex;
      padding: 6px var(---8, 8px);
      align-items: center;
      gap: 6px;
      border-radius: var(---4, 4px);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: inline-block;
    }
    .file_s {
      gap: var(---8, 8px);
      display: flex;
      justify-content: center;
      align-items: center;
      .down_file_css {
        display: block;
        color: #6b8cfa;
        margin: 0 8px 0 0;
        cursor: pointer;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    .footer {
      // height: 50px;
      // line-height: 50px;
      // width: 80%;
      // background: rgba(0, 0, 0, 0.5);
      // position: fixed;
      // bottom: 10px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .pag {
    display: flex;
    // justify-content: center;
    // justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    margin: 0 0 70px 0;
  }
}
.greater {
  min-width: 650px;
  height: 800px;
  ul::-webkit-scrollbar {
    display: none;
  }
  ul {
    max-height: 500px;
    display: inline-block;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
      display: inline-block;
      padding: 15px 5px;
      // margin-bottom: 20px;
      .newBook {
        p {
          // height: 20px;
          // display: flex;
          line-height: 20px;
          margin-bottom: 8px;
          display: flex;
          // justify-content: space-between;
          // align-items: center;
        }
        .span_tit {
          width: 100px;
          display: inline-block;
        }
        .span_con {
          width: calc(100% - 100px);
          display: inline-block;
        }
      }
    }
  }
}
.stats {
  .el-button--mini {
    width: 90px;
  }
}
.readable {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 10px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e2426b;
  // background-image: linear-gradient(to right, #6861c9, #8067dc);
  color: #fff;
}
.el-popover {
  p {
    display: inline-block;
    display: flex;
    span {
      display: inline-block;
      min-width: 70px;
    }
  }
}
.oneRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  .el-tag {
    background: #e9f8ff !important;
    color: #42ade2 !important;
    border-color: #e9f8ff !important;
    padding: 4px 8px;
    border-radius: 6px;
    line-height: 18px;
    height: 28px;
  }
  // display: -webkit-box;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // width: 100px;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
}
.ceList {
  .tit {
    span {
      display: inline-block;
    }
  }
  .type {
    span {
      display: inline-block;
    }
  }
}

.el-icon-document-copy {
  display: inline-block;
  margin: 0 10px;
}
.el-icon-document-copy:hover {
  color: #67badb;
  cursor: pointer;
}
// .urlText::-webkit-scrollbar {
//   display: none;
// }
// .urlText {
//   max-width: 270px;
//   display: inline-block;
//   // overflow-x: hidden;
//   overflow-y: scroll;
//   white-space: nowrap;
// }
.btns {
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  // justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  // justify-content: center;
}
.down_links {
  // margin-top: 8px;
  background-image: linear-gradient(to bottom, #b14a75, #b14a75);
  color: white;
  z-index: 99;
}
.down_links1 {
  background-image: linear-gradient(to bottom, #f56c6c, #f56c6c);
  color: white;
  z-index: 99;
}
.dropdown {
  width: 100px;
  ul {
    li {
      text-align: center;
      line-height: 30px;
    }
    li:hover {
      cursor: pointer;
      color: #409eff;
      background: #ecf5ff;
      // color: #4ac264;
      // background: #909399;
    }
  }
}
.link_css {
  display: flex;
  // padding: 24px 24px 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.link_url_css {
  width: 100%;
}
.link_url {
  display: flex;
  align-items: center;
  // margin-bottom: 16px;
  span {
    display: inline-block;
  }
  i {
    display: inline-block;
    cursor: pointer;
  }
  line-height: 24px;
  .url_tit {
    color: var(---, #6b6e73);
  }
  .inputText {
    padding: 0px 24px;
    border-radius: 5px;
    height: 24px;
    color: var(---600, #3c68f8);

    flex: 1 0 0;
    // height: 40px;
    // background-color: #f5f7fa;
    span {
      // display: inline-block;
      margin: 0 0px 0 0;
      text-decoration: underline;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
.password_css {
  display: flex;
  align-items: center;
  width: 100%;
  .inputText {
  }
}
.pdfdown {
  display: flex;
  justify-content: center;
}
.anew_css {
  margin: 7px 0 0 0;
  gap: 16px;
  /deep/ .el-radio {
    display: block;
    margin: 5px 0;
  }
}
.top_css {
  padding: 16px 20px;
  gap: 20px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(102, 105, 119, 0.06);
  margin: 0 0 16px 0;
}
.read_css {
  width: var(---4, 4px);
  height: 53px;
  background: #e2426b;
}

.cssCancel,
.cssConfirm {
  display: flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;

  line-height: 22px;
}
.operate_btn {
  display: flex;
  align-items: center;
  padding: var(---4, 4px) var(---8, 8px);

  border-radius: var(---4, 4px);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: var(--Gray-Gray-200, #f1f1f2);
  color: var(---, #262729);
  border: none;
  position: relative;
  cursor: pointer;
}
.new_icon_css{
  width: 30px;
  position: absolute;
  top: -7px;
  right: 0px;
}
.operate_btn:hover {
  background: var(---500, #d6d7d9);
}
.operate_btn_css {
  display: inline-flex;
  padding: var(---4, 4px) var(---8, 8px);
  align-items: flex-start;
  border-radius: var(---4, 4px);
  background: #f1f1f2;
  line-height: 20px;
  color: var(---, #262729);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.operate_btn_css:hover {
  background: var(---500, #d6d7d9);
}
/deep/ .half-border-left {
  position: relative;
  // background: red;
}

/deep/ .half-border-left::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10%; /* 距离顶部 25%，使边框垂直居中 */
  height: 80%; /* 高度为单元格高度的 50% */
  width: 4px; /* 边框宽度 */
  background-color: #e2426b; /* 边框颜色 */
}
.allRead_btn {
  display: flex;
  padding: 5px 9px;
  align-items: center;
  gap: var(---4, 4px);
  color: var(---, #8d8f94);
  border-radius: var(---4, 4px);
  cursor: pointer;
  line-height: 22px;
}
.allRead_btn:hover {
  // background: #F5F5F5;
  color: var(---, #262729);
}
.allRead_btn:hover img {
  content: url("../../../assets/imgs/2.2/read_yes.png"); /* 鼠标悬浮时替换图片路径 */
  transform: scale(1); /* 可选：放大效果 */
}
/deep/ .el-table__body-wrapper{
  width: 100%;
overflow-x: scroll;
overflow-y: hidden;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb { //滑块部分
  border-radius: 5px;
  background-color: #dde;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-track { //轨道部分
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #ffffff;
  border-radius: 5px;

 

}

/deep/ .el-loading-text {
  color: #e2426b !important;
}
/deep/ .el-loading-spinner i {
  color: #e2426b !important;
}
.z-index {
  position: relative;
  z-index: 100;
}

/deep/ .el-cascader .el-input {
  opacity: 0;
  width: 88px;
}
.cascader_css {
  width: 88px;
  position: relative;
}
.cascader_css:hover .operate_btn {
  background: var(---500, #d6d7d9);
}
.pdf_xiazai {
  position: absolute;
  left: 0;
  top: 0;
  //   position: absolute;
  //   left: 0;
  //   top: 7px;
  z-index: 0;
}
</style>
<style  lang='less'>
.dialog_file_css {
  border-radius: var(---8, 8px) !important;
  .el-dialog__header {
    padding: 24px 16px 0px 24px;
  }
  .el-dialog__title {
    color: #000;
    font-weight: 600;
  }
  .el-dialog__body {
    padding: 24px 24px 0px 24px;
  }
  .el-dialog__footer {
    padding: 24px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }

  .el-radio__input.is-checked .el-radio__inner {
    color: #e2426b;
    background: #e2426b;
  }
  .el-radio__label {
    color: var(---, #8d8f94);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: var(---, #262729);
  }
  .el-form-item {
    // gap: 24px;
    margin: 0;
  }
}
.el-message-box__container {
  .el-icon-warning {
    color: #e2426b;
  }
}
.dialog_btn {
  padding: 6px 14px;
  line-height: 22px;
}
.cssCancel {
  color: var(---, #262729);
  background: var(---300, #f1f2f2);
  border: none !important;
}
.cssCancel:hover {
  background: var(---300, #f1f2f2);
}
.cssConfirm {
  color: var(--white-dark-white, #fff);
  background: #e2426b;
  border: none !important;
}
.cssConfirm:hover {
  background: #e2426b;
}
.footer_btn {
  display: flex;
  padding: 6px 14px;
  justify-content: flex-end;
  line-height: 22px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #e2426b;
  color: var(--white-dark-white, #fff);
  border: none;
}
.footer_btn:hover {
  background: #e2426b;
  color: var(--white-dark-white, #fff);
}
.train-tree .el-cascader-menu {
  min-width: 120px !important;
}
.popovers{
  /deep/ .el-popover__reference-wrapper{
    width:100%;
  }
}
</style>

